<template>
    <section style="height: calc(100vh - 146px);">
        <div class="d-none select-responsive mx-0 py-2">
            <div class="col-4">
                <el-select v-model="value" placeholder="Seleccionar pedido" class="w-100" @change="seleccionar_pedido(value)">
                    <el-option
                    v-for="(item, idx) in pedidos"
                    :key="idx"
                    :label="`ID-${item.id}`"
                    :value="item.id"
                    >
                        <p class="text-general">
                            <b>ID-{{ item.id }}</b>
                        </p>
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="row mx-0 h-100">
            <div class="menu-prinicipal h-100 bg-white br-t-8">
                <div class="row mx-0 py-2 pl-2 border-bottom">
                    <div class="col pl-0 my-auto">
                        <el-input v-model="buscar" size="small" clearable placeholder="Buscar" class="br-20" />
                    </div>
                    <el-tooltip class="item" effect="light" content="Filtro" placement="bottom">
                        <div class="btn-action cr-pointer border cr-pointer br-8 d-middle-center text-general" @click="openFiltro">
                            <i class="icon-filter-outline f-22" />
                        </div>
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" content="Crear pedido" placement="bottom">
                        <div class="btn-red mx-2 d-middle-center br-8" @click="$router.push({name: 'tendero.carrito'})">
                            <i class="icon-plus f-15 text-white" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="listar-productos col px-0 br-8 overflow-auto custom-scroll" style="width:400px;height:calc(100% - 55px);">
                    <div v-if="lecheros.length" class="row mx-3 my-3 justify-center">
                        <el-select v-model="lechero_seleccionado" placeholder="Seleccionar Vendedor" size="small" class="w-100">
                            <el-option
                            v-for="(l, idx) in lecheros"
                            :key="idx"
                            :label="l.lechero"
                            :value="l.id_tienda"
                            />
                        </el-select>
                    </div>
                    <div class="row mx-3 mt-2 mb-3">
                        <el-tooltip class="item" effect="light" content="Pedidos recibidos" placement="bottom">
                            <div class="br-20 bg-gris2 px-2 mx-1 text-white">
                                <i class="icon-receipt f-20" />
                                <span class="mx-2">{{ indicadores.recibidos }}</span>
                            </div>
                        </el-tooltip>
                        <el-tooltip class="item" effect="light" placement="bottom">
                            <div slot="content" class="text-center" style="width:110px;">
                                Pedidos cancelados por tiempo
                            </div>
                            <div class="br-20 bg-gris2 px-2 mx-1 text-white">
                                <i class="icon-clock-alert-outline f-20" />
                                <span class="mx-2">{{ indicadores.cancelados_tiempo }}</span>
                            </div>
                        </el-tooltip>
                        <el-tooltip class="item" effect="light" content="Pedidos cancelados" placement="bottom">
                            <div class="br-20 bg-gris2 px-2 mx-1 text-white">
                                <i class="icon-cancel-circled-outline f-20" />
                                <span class="mx-2">{{ indicadores.cancelados }}</span>
                            </div>
                        </el-tooltip>
                        <el-tooltip class="item" effect="light" content="Pedidos entregados" placement="bottom">
                            <div class="br-20 bg-gris2 px-2 mx-1 text-white">
                                <i class="icon-shopping f-20" />
                                <span class="mx-2">{{ indicadores.entregados }}</span>
                            </div>
                        </el-tooltip>
                    </div>
                    <!-- listado pedidos -->
                    <div class="sin-confirmar">
                        <div class="row mx-3 mb-3 align-items-center container-tipo-pedido px-3 br-20 border cr-pointer" @click="mostrarSinConfirmar = !mostrarSinConfirmar">
                            <span>Sin confirmar</span>
                            <div class="br-20 f-12 bg-general3 f-600 p-1 text-white d-middle-center ml-auto" style="min-width:20px;height:20px;">
                                {{ sin_confirmar.length }}
                            </div>
                            <i class="text-general2 f-18 ml-2" :class="mostrarSinConfirmar ? 'icon-angle-up' : 'icon-angle-down'" />
                        </div>
                        <template v-if="mostrarSinConfirmar">
                            <div
                            v-for="(sc, idx) in sin_confirmar" :key="idx"
                            class="row mx-0 d-middle cr-pointer mb-3 mt-2 pb-2"
                            @click="seleccionar_pedido(sc.id)"
                            >
                                <div :class="`mx-2 px-1 br-5 ${activo==sc.id?'bg-general':''}`" style="height:120px;" />
                                <card-pedido :data="sc" />
                            </div>
                        </template>
                    </div>
                    <div class="confirmados">
                        <div class="row mx-3 mb-3 align-items-center container-tipo-pedido px-3 br-20 border cr-pointer" @click="mostrarConfirmados = !mostrarConfirmados">
                            <span>Confirmados</span>
                            <div class="br-20 f-12 bg-general3 text-white f-600 p-1 d-middle-center ml-auto" style="min-width:20px;height:20px;">
                                {{ confirmados.length }}
                            </div>
                            <i class="text-general2 f-18 ml-2" :class="mostrarConfirmados ? 'icon-angle-up' : 'icon-angle-down'" />
                        </div>
                        <template v-if="mostrarConfirmados">
                            <div
                            v-for="(c, idx) in confirmados" :key="idx"
                            class="row mx-0 d-middle cr-pointer mb-3 mt-2 pb-2"
                            @click="seleccionar_pedido(c.id)"
                            >
                                <div :class="`mx-2 px-1 br-5 ${activo==c.id?'bg-general':''}`" style="height:120px;" />
                                <card-pedido :data="c" />
                            </div>
                        </template>
                    </div>
                    <div class="alistamiento">
                        <div class="row mx-3 mb-3 align-items-center container-tipo-pedido px-3 br-20 border cr-pointer" @click="mostrarAlistamiento = !mostrarAlistamiento">
                            <span>Alistamiento</span>
                            <div class="br-20 f-12 bg-general3 text-white f-600 p-1 d-middle-center ml-auto" style="min-width:20px;height:20px;">
                                {{ alistados.length }}
                            </div>
                            <i class="text-general2 f-18 ml-2" :class="mostrarAlistamiento ? 'icon-angle-up' : 'icon-angle-down' " />
                        </div>
                        <template v-if="mostrarAlistamiento">
                            <div
                            v-for="(a, idx) in alistados" :key="idx"
                            class="row mx-0 d-middle cr-pointer mb-3 mt-2 pb-2"
                            @click="seleccionar_pedido(a.id)"
                            >
                                <div :class="`mx-2 px-1 br-5 ${activo==a.id?'bg-general':''}`" style="height:140px;" />
                                <card-pedido :data="a" />
                            </div>
                        </template>
                    </div>
                    <div class="empacados">
                        <div class="row mx-3 mb-3 align-items-center container-tipo-pedido px-3 br-20 border cr-pointer" @click="mostrarEmpacados = !mostrarEmpacados">
                            <span>Empacados</span>
                            <div class="br-20 f-14 bg-general3 text-white f-14 f-600 p-1 d-middle-center ml-auto" style="min-width:20px;height:20px;">
                                {{ empacados.length }}
                            </div>
                            <i class="text-general2 f-18 ml-2" :class="mostrarEmpacados ? 'icon-angle-up' : 'icon-angle-down'" />
                        </div>
                        <template v-if="mostrarEmpacados">
                            <div
                            v-for="(e, idx) in empacados" :key="idx"
                            class="row mx-0 d-middle cr-pointer mb-3 mt-2 pb-2"
                            @click="seleccionar_pedido(e.id)"
                            >
                                <div :class="`mx-2 px-1 br-5 ${activo==e.id?'bg-general':''}`" style="height:120px;" />
                                <card-pedido :data="e" />
                            </div>
                        </template>
                    </div>
                    <div class="enviados">
                        <div class="row mx-3 mb-3 align-items-center container-tipo-pedido px-3 br-20 border cr-pointer" @click="mostrarEnviados = !mostrarEnviados">
                            <span>Enviados</span>
                            <div class="br-20 f-12 bg-general3 text-white f-600 p-1 d-middle-center ml-auto" style="min-width:20px;height:20px;">
                                {{ enviados.length }}
                            </div>
                            <i class="text-general2 f-18 ml-2" :class="mostrarEnviados ? 'icon-angle-up' : 'icon-angle-down'" />
                        </div>
                        <template v-if="mostrarEnviados">
                            <div
                            v-for="(e, idx) in enviados" :key="idx"
                            class="row mx-0 d-middle cr-pointer mb-3 mt-2 pb-2"
                            @click="seleccionar_pedido(e.id)"
                            >
                                <div :class="`mx-2 px-1 br-5 ${activo==e.id?'bg-general':''}`" style="height:120px;" />
                                <card-pedido :data="e" />
                            </div>
                        </template>
                    </div>
                    <div v-show="sin_confirmar.length === 0 && confirmados.length === 0 && enviados.length === 0 && alistados.length == 0 && empacados.length == 0" class="row mx-0 mt-4 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="110" src="/img/no-imagen/imgDefault.svg" alt="" />
                            </div>
                            <p>Esperamos que estés preparado ¡Ya vienen los pedidos!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 bg-white col br-t-8 menu-prinicipal-content h-100 ml-3" style="width: calc(100vw - 560px);">
                <listado />
            </div>
        </div>
        <modalCrearPedido ref="modalCrearPedido" />
        <filtro-pedidos ref="modalFiltro" :opcion="4" @filtrar="accion_filtro" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Pedidos from '~/services/pedidos/pedidos-tienda'
import moment from 'moment'
export default {
    sockets: {
        pedidos(obj){
            console.log(obj);
            this.verificar_pedido(obj)
        },
        chats_pedidos(value){ // Emite Cuando se escribe en tabla pedidos_chat
            try {
                const {chat, pedidoChat} = JSON.parse(value)
                if(this.$usuario.tienda.id === pedidoChat.id_tienda){// Si el mensaje es de un pedido de este tendero
                    // console.log(this.$route.params.id_pedido, chat.id_pedido, (this.$route.params.id_pedido != chat.id_pedido));
                    if(chat.mio !== 1 && chat.momento==2 && parseInt(this.$route.params.id_pedido) !== chat.id_pedido){// cuando No tengo abierto  el chat del pedido actual entonces notifico
                        this.notifiChat(2,chat, pedidoChat)
                        let idx_confirmado = this.confirmados.findIndex(e => e.id === pedidoChat.id)
                        let idx_enviado= this.enviados.findIndex(e => e.id === pedidoChat.id)
                        if(idx_confirmado >= 0){
                            let found = this.confirmados[idx_confirmado]
                            found.cant_mensajes = found.cant_mensajes +1
                        }else if(idx_enviado >= 0){
                            let found = this.enviados[idx_enviado]
                            found.cant_mensajes = found.cant_mensajes +1
                        }
                    }
                }

            } catch (e){
                this.error_catch(e)
            }
        }
    },
    middleware:['auth','estadoTienda'],
    metaInfo: () =>({ title: 'Pedidos'}),
    components: {
        listado: () => import('./listado'),
        modalCrearPedido: () => import('./partials/modalCrearPedido'),
        cardPedido: () => import('./components/cardPedidos')
    },
    data(){
        return {
            buscar: '',
            activo: -1,
            value: '',
            lechero_seleccionado:0,
            mostrarSinConfirmar: false,
            mostrarConfirmados: false,
            mostrarAlistamiento: false,
            mostrarEmpacados:false,
            mostrarEnviados: false
        }
    },
    computed:{
        ...mapGetters({
            indicadores: 'pedidos/pedidos/indicadores',
            pedidos: 'pedidos/pedidos/pedidos',
            pedido: 'pedidos/pedidos/pedido',
            lecheros: 'pedidos/pedidos/lecheros',
        }),
        sin_confirmar(){
            return this.fill_pedidos.filter(p=> [1].some(s => s == p.estado))
        },
        confirmados(){
            return this.fill_pedidos.filter(p=> [2,110,11,12,13].some(s => s == p.estado))
        },
        alistados(){
            return this.fill_pedidos.filter(p=> p.estado === 201)
        },
        empacados(){
            return this.fill_pedidos.filter(p=> p.estado === 202)
        },
        enviados(){
            return this.fill_pedidos.filter(p=> [3,31].some(s => s == p.estado))
        },
        id_pedido(){
            return this.$route.params.id_pedido
        },
        fill_pedidos(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.pedidos.filter(el =>
                    (this.lechero_seleccionado == 0 ||
                    el.id_tienda == this.lechero_seleccionado) &&
                    (String(el.id).includes(this.buscar.toLowerCase()) ||
                    String(el.nombre).toLowerCase().includes(this.buscar.toLowerCase()) ||
                    String(el.direccion).toLowerCase().includes(this.buscar.toLowerCase()))
                )
            }else{
                return this.pedidos.filter(el =>
                    this.lechero_seleccionado == 0 ||
                    el.id_tienda == this.lechero_seleccionado
                )
            }
        }
    },
    watch:{
        id_pedido(val){
            //console.log('paso por watch');
            this.cambioPedido()
        },
    },
    mounted(){
        this.sincronizar()
    },
    methods: {
        async cambioPedido(){
            if(this.id_pedido != undefined && this.id_pedido != NaN && this.id_pedido != null && this.id_pedido != 0 ){
                let validando = await this.validar_estado_pedido(Number(this.id_pedido))
                if(!validando) return
                let pedido = await this.$store.dispatch('pedidos/pedidos/get_datos', Number(this.id_pedido))
                // console.log(pedido);
                if(pedido.error){
                    this.notificacion('ERROR','Pedido Invalido','warning')
                    return
                }
                this.$store.commit('pedidos/pedidos/set_id_pedido', Number(this.$route.params.id_pedido))

                if(this.pedido.pago_estado === 2){
                    this.$store.dispatch('pedidos/pedidos/pedido_credito', this.id_pedido)
                }
                await this.$store.dispatch('pedidos/pedidos/ver_pedido', this.id_pedido)
                this.activo = this.$route.params.id_pedido
            }else{
                this.$store.commit('pedidos/pedidos/set_id_pedido', null)
            }
        },
        async sincronizar(){
            try {
                this.lista_indicadores()
                this.listar_pedidos()
                if(this.id_pedido != undefined && this.id_pedido != NaN && this.id_pedido != null && this.id_pedido != 0 ){
                    let validando = await this.validar_estado_pedido(Number(this.id_pedido))
                    if(!validando) return
                    let pedido = await this.$store.dispatch('pedidos/pedidos/get_datos', Number(this.id_pedido))
                    // console.log(pedido);
                    if(pedido.error){
                        this.notificacion('ERROR','Pedido Invalido','warning')
                        return
                    }
                    this.$store.commit('pedidos/pedidos/set_id_pedido', Number(this.$route.params.id_pedido))

                    if(this.pedido.pago_estado === 2){
                        this.$store.dispatch('pedidos/pedidos/pedido_credito', this.id_pedido)
                    }
                    await this.$store.dispatch('pedidos/pedidos/ver_pedido', this.id_pedido)
                    //this.$store.dispatch('pedidos/pedidos/pedidos_historial', Number(this.$route.params.id_pedido))
                    //this.$store.dispatch('pedidos/pedidos/pedidos_resumenes', Number(this.$route.params.id_pedido))
                    this.activo = this.$route.params.id_pedido
                }else{
                    this.$store.commit('pedidos/pedidos/set_id_pedido', null)
                }
            } catch (e){
                this.error_catch(e)
            }

        },
        verificar_pedido(item){
            const {id,tipo,data} = JSON.parse(item)

            if(data.id_user === this.$usuario.id){ // Solo actualizar al tendero necesario
                if(tipo === 112){ // Pedido cancelado por el cliente
                    this.$route.params.id_pedido = null
                    this.$router.push({name:`tendero.pedidos`})
                    this.sincronizar()
                }else if(tipo === 111){ // Nuevo pedido
                    this.lista_indicadores()
                    this.listar_pedidos()
                }else{ // pedido directo
                    if(id == this.$route.params.id_pedido){
                        this.sincronizar() //  default
                        this.$store.dispatch('pedidos/pedidos/pedidos_productos', this.$route.params.id_pedido)
                    }
                }
            }
        },
        async validar_estado_pedido(id){
            const {data} = await Pedidos.validar_estado(id)
            if([1,2,201,202,11,110,12,13,3,31].indexOf(data.estado) < 0){
                this.$router.push({name:`tendero.historial-pedidos`})
                return false
            }else{
                return true
            }
        },
        async lista_indicadores(){
            try {
                await this.$store.dispatch('pedidos/pedidos/pedidos_indicadores')
            } catch (e){
                this.error_catch(e)
            }
        },
        async listar_pedidos(){
            try {
                await this.$store.dispatch('pedidos/pedidos/listar_pedidos')
            } catch (e){
                this.error_catch(e)
            }
        },
        seleccionar_pedido(id){
            this.activo = id
            if(id !== Number(this.$route.params.id_pedido)){
                this.$router.push({params:{id_pedido:id}})
            }
            this.$store.commit('pedidos/pedidos/set_id_pedido', id)
        },
        tiempo(fecha){
            return moment(fecha).fromNow()
        },
        crearPedido(){
            this.$refs.modalCrearPedido.toggle()
        },
        tipoPed(tipo){
            switch (tipo){
            case 110:
            case 11:
                return 'tipo-naranja'
            case 13:
                return 'tipo-black'
            case 12:
                return 'tipo-azul'
            case 31:
                return 'tipo-rojo'
            default:
                return ''
            }
        },
        iconPedido(tipo){
            switch (tipo){
            case 110:
            case 11:
                return 'icon-playlist-edit'
            case 13:
                return 'icon-account-edit '
            case 12:
                return 'icon-account-check'
            case 5:
                return 'icon-storefront'
            case 31:
                return 'icon-attention-alt'
            default:
                return ''
            }
        },
        accion_filtro(obj){

            this.$store.commit('pedidos/pedidos/set_filtro', obj)

            this.listar_pedidos()
        },
        openFiltro(){
            this.$refs.modalFiltro.open()
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-prinicipal{
    background: #fff;

    .container-tipo-pedido{
        background-color: #DFE4E8;
        color: #000000;
        height: 35px;
    }

}
.text-blue{
    color: #0D4DFF;
}
.text-pink{
    color: #D6017B;
}
.icono{
    border-radius: 0 0 10px 0;
    padding-left: 5px;
    padding-right: 5px;
    i{
        //top:7px;
        font-size: 25px;
        position: relative;
    }
}
.tipo-black{
    border: 1px solid #5D5D5D;
    .icono{
        background: #5D5D5D;
    }
}
.tipo-azul{
    border: 1px solid #0D4DFF;
    .icono{
        background: #0D4DFF;
    }
}
.tipo-rosa{
    border: 1px solid #D6017B;
    .icono{
        background: #D6017B;
    }
}
.tipo-rojo{
    border: 1px solid #FF2525;
    .icono{
        background: #FF2525;
    }
}

/* Media Queries */

@media(max-width:980px){
    .menu-prinicipal{
        display: none !important;
    }
    .select-responsive{
        display: flex !important;
    }
    section .row{
        height: auto !important;
    }
}

@media (min-width:980px) and (max-width:1173px) {
    .menu-prinicipal .listar-productos{
        width: 345px !important;
    }
}
</style>
